import {
  GET_GREETINGS_BEGIN,
  GET_GREETINGS_SUCCESS,
  GET_GREETINGS_ERROR,
  POST_GREETING,
} from '../actions.js'

const greeting_reducer = (state, action) => {
  if (action.type === GET_GREETINGS_BEGIN) {
    return { ...state, greetingLoading: true }
  }

  if (action.type === GET_GREETINGS_SUCCESS) {
    return {
      ...state,
      greetingLoading: false,
      greetings: action.payload,
    }
  }

  if (action.type === GET_GREETINGS_ERROR) {
    return { ...state, greetingLoading: false, greetingError: true }
  }

  if (action.type === POST_GREETING) {
    return {
      ...state,
      // greetingLoading: false,
      greetings: action.payload,
    }
  }

  throw new Error(`No matching "${action.type}" - action type`)
}

export default greeting_reducer

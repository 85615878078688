import React from 'react'
import Bride from '../../../components/DesignOne/Bride'
import Hero from '../../../components/DesignOne/Hero'
import Navbar from '../../../components/DesignOne/Navbar'
import WeddingInfo from '../../../components/DesignOne/WeddingInfo'
import Gallery from '../../../components/DesignOne/Gallery'
import Story from '../../../components/DesignTwo/Story' // Design Two
import Rsvp from '../../../components/DesignOne/Rsvp'
import Gifts from '../../../components/DesignOne/Gifts'
import Greeting from '../../../components/DesignOne/Greeting'
import Footer from '../../../components/DesignOne/Footer'

const TemplateOne = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const to = queryParams.get('to')

  return (
    <div>
      <Hero guest={to} />
      <Navbar />
      <Bride />
      <WeddingInfo />
      <Story />
      <Gallery />
      <Rsvp guest={to} />
      <Gifts />
      <Greeting guest={to} />
      <Footer />
    </div>
  )
}

export default TemplateOne

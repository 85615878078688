import React from 'react'
import { ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons'
import { ZoomInRight } from '../Animation'

const WeddingInfo = () => {
  return (
    <section className="lg:py-40 py-28 bg-hero-pattern-2 bg-cover text-gray-500">
      <ZoomInRight>
        <div className="container mx-auto text-center">
          <h2 className="font-sacramento mx-5 lg:mx-0 text-7xl font-extrabold mb-10">
            Informasi Acara
          </h2>
          <h3 className="lg:text-xl lg:font-bold font-semibold lg:px-0 px-8">
            Tanpa mengurangi rasa hormat, dengan ini kami bermaksud mengundang
            Bapak/Ibu/Saudara/i untuk menghadiri acara pernikahan kami yang
            InsyaAllah akan dilaksanakan pada:
          </h3>

          <div className="flex flex-col gap-7 lg:flex-row mt-16 justify-center items-center">
            <div className="w-5/6 md:w-3/5 lg:w-1/3 mb-5 h-max border-solid border-2 border-white rounded-xl bg-gray-400 bg-opacity-60 text-gray-900">
              <h5 className="border-b-2 font-sacramento text-3xl border-white border-solid py-3">
                Akad Nikah
              </h5>
              <div>
                <div className="p-4">
                  <h3 className="font-bold text-lg">
                    Rumah Kediaman Mempelai Wanita:
                  </h3>
                  <p className="lg:text-base text-sm font-semibold">
                    Jalan R.A Kartini No. 06, Kel. Purwodadi, Kec. Arga Makmur,
                    Kab. Bengkulu Utara
                  </p>
                </div>
                <div className="py-5 flex justify-around lg:flex-row flex-col font-extrabold">
                  <div className="pb-3 lg:pb-0">
                    <ClockCircleOutlined /> <br />
                    <span>08.00 - Selesai</span>
                  </div>
                  <div>
                    <CalendarOutlined /> <br />
                    <span>Sabtu, 04 November 2023</span>
                  </div>
                </div>
              </div>
              <p className="border-t-2 font-semibold text-sm border-white border-solid py-3">
                {' '}
                Diharapkan kondusif untuk menjaga kekhidmatan
              </p>
            </div>

            <div className="w-5/6 md:w-3/5 lg:w-1/3 mb-5 h-max border-solid border-2 border-white rounded-xl bg-gray-400 bg-opacity-50 text-gray-900">
              <h5 className="border-b-2 font-sacramento text-3xl border-white border-solid py-3">
                Walimah
              </h5>
              <div>
                <div className="p-4">
                  <h3 className="font-bold text-lg">
                    Rumah Kediaman Mempelai Wanita:
                  </h3>
                  <p className="lg:text-base text-sm font-semibold">
                    Jalan R.A Kartini No. 06, Kel. Purwodadi, Kec. Arga Makmur,
                    Kab. Bengkulu Utara
                  </p>
                </div>
                <div className="py-5 flex justify-around lg:flex-row flex-col font-extrabold">
                  <div className="pb-3 lg:pb-0">
                    <ClockCircleOutlined /> <br />
                    <span>10.00 - Selesai</span>
                  </div>
                  <div>
                    <CalendarOutlined /> <br />
                    <span>Sabtu, 04 November 2023</span>
                  </div>
                </div>
              </div>
              <p className="border-t-2 text-sm font-semibold border-white border-solid py-3">
                {' '}
                Diharapkan kondusif untuk menjaga kekhidmatan
              </p>
            </div>
          </div>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d939.7785026245567!2d102.18912416375014!3d-3.4368593263016156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e315b9462b8b755%3A0x6483b9b66541b556!2sJl.%20RA.%20Kartini%20No.6%2C%20Air%20Merah%2C%20Kec.%20Arga%20Makmur%2C%20Kabupaten%20Bengkulu%20Utara%2C%20Bengkulu%2038611!5e0!3m2!1sen!2sid!4v1697119685340!5m2!1sen!2sid"
            width="75%"
            className="border-0 mb-8 mx-auto"
            height="250"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>

          <a
            href="https://maps.app.goo.gl/W6EqVTAfHTN4WkL9A"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-slate-100 hover:bg-gray-900 rounded p-3 font-semibold text-gray-500"
          >
            Klik untuk membuka peta
          </a>
        </div>
      </ZoomInRight>
    </section>
  )
}

export default WeddingInfo

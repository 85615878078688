import React from 'react'
import { SmileOutlined, HeartOutlined } from '@ant-design/icons'

const ComingSoon = () => {
  return (
    <div className="text-center">
      <SmileOutlined className="text-7xl" />
      <h1 className="text-5xl my-5 font-black">Coming soon!</h1>
      <h3 className="font-bold">
        We are under process of creating something amazing!
      </h3>
      <h3 className="font-bold">Our website will be live pretty soon:)</h3>
      <h5 className="mt-10">
        Made with <HeartOutlined /> by{' '}
        <a
          href="http://instagram.com/infiniteinvites"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:underline"
        >
          @infiniteinvites
        </a>
      </h5>
    </div>
  )
}

export default ComingSoon

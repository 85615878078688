import React from 'react'
import Bride from '../../assets/images/jessy.png'
import Groom from '../../assets/images/wahyu.png'
import { FadeUp } from '../Animation'

const Story = () => {
  return (
    <section className="lg:py-40 py-28 bg-hero-pattern-2 bg-cover text-gray-500">
      <FadeUp>
        <div className="container mx-auto text-center">
          <h2 className="font-sacramento mx-5 lg:mx-0 text-6xl lg:text-7xl font-extrabold">
            Cerita Kami
          </h2>
        </div>

        <div className="flex justify-center mb-5">
          <img src={Bride} alt="Mempelai Wanita" className="lg:w-1/5 w-2/5" />
          <img src={Groom} alt="Mempelai Wanita" className="lg:w-1/5 w-2/5" />
        </div>

        <div>
          <ul className="text-center">
            <li className="mb-10 lg:px-32 md:px-24 px-10">
              <h3 className="uppercase text-xl font-bold">Awal bertemu</h3>
              <small className="font-bold">Desember 2013</small>
              <div className="border-b-2 opacity-50 mx-auto border-gray-400 my-4 w-1/2"></div>
              <p className="lg:text-base text-sm leading-6 lg:leading-7 font-bold">
                Pertemuan awal kami terjadi pada awal Desember tahun 2013 saat
                Jessy mengikuti lomba Karya Tulis Ilmiah yang diadakan oleh
                Universitas Airlangga, dimana ternyata Wahyu adalah ketua
                panitia lomba tersebut. Wahyu sangatlah akrab dan cepat
                membangun interaksi sehingga kami merasa nyaman satu sama lain,
                namun pertemuan itu hanya terjadi beberapa hari saja dan
                menyimpan kenangan yang membekas.
              </p>
            </li>

            <li className="mb-10 lg:px-32 md:px-24 px-10">
              <h3 className="uppercase text-xl font-bold">Intention</h3>
              <small className="font-bold">Oktober 2022</small>
              <div className="border-b-2 opacity-50 mx-auto border-gray-400 my-4 w-1/2"></div>
              <p className="lg:text-base text-sm leading-6 lg:leading-7 font-bold">
                Selama hampir 9 tahun kami memiliki hidup dan tujuan
                masing-masing, bahkan komunikasi kami hanya sebatas saling
                berbalas "story". Namun pada bulan Oktober 2022, dengan niat
                yang baik, Wahyu menyampaikan maksud dan tujuannya untuk
                mengenal Jessy lebih mendalam. Kami lalu berikhtiar dan
                beristikharah bersama untuk mendapatkan jawaban terbaik dari
                Allah.
              </p>
            </li>

            <li className="mb-10 lg:px-32 md:px-24 px-10">
              <h3 className="uppercase text-xl font-bold">Komitmen</h3>
              <small className="font-bold">Juni 2023</small>
              <div className="border-b-2 opacity-50 mx-auto border-gray-400 my-4 w-1/2"></div>
              <p className="lg:text-base text-sm leading-6 lg:leading-7 font-bold">
                Setelah proses yang cukup lama dalam menemukan jawaban terbaik
                dari Allah, serta jalan yang mulai terbuka bagi kami, Wahyu
                mendatangi keluarga Jessy di Arga Makmur untuk menyampaikan
                itikad baiknya dan begitu pun sebaliknya. Alhamdulillah, dengan
                ridho dari kedua belah pihak keluarga dan Allah SWT, kami sampai
                pada jenjang yang lebih serius yakni ibadah sepanjang hayat
                (Menikah).
              </p>
            </li>
          </ul>
        </div>
      </FadeUp>
    </section>
  )
}

export default Story

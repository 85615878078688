import React from 'react'
import ComingSoon from './ComingSoon'

const LandingPage = () => {
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center bg-gray-300">
      <ComingSoon />
    </div>
  )
}

export default LandingPage

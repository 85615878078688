import React, { forwardRef } from 'react'
import { FadeUpRight } from '../Animation'

const Quote = forwardRef((props, ref) => {
  return (
    <section
      ref={ref}
      className="lg:py-40 py-28 bg-hero-pattern-2 bg-cover text-gray-500"
    >
      <FadeUpRight>
        <div className="text-center lg:p-14 p-8 mx-auto w-4/5 rounded-lg ">
          <h2 className="lg:text-3xl md:text-2xl text-xl lg:leading-relaxed md:leading-relaxed leading-loose font-arabNoto mb-8">
            وَمِنْ اٰيٰتِهٖٓ اَنْ خَلَقَ لَكُمْ مِّنْ اَنْفُسِكُمْ اَزْوَاجًا
            لِّتَسْكُنُوْٓا اِلَيْهَا وَجَعَلَ بَيْنَكُمْ مَّوَدَّةً وَّرَحْمَةً
            ۗاِنَّ فِيْ ذٰلِكَ لَاٰيٰتٍ لِّقَوْمٍ يَّتَفَكَّرُوْنَ
          </h2>
          <div className="border-b-4 border-solid border-gray-500 lg:w-1/2 mx-auto my-10"></div>
          <p className="lg:text-2xl md:text-lg text-base font-patrick">
            "Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan
            pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung
            dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa
            kasih dan sayang. Sungguh, pada yang demikian itu benar-benar
            terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir."{' '}
            <br /> <span className="font-bold">Q.S Ar-Rum:21</span>
          </p>
        </div>
      </FadeUpRight>
    </section>
  )
})

export default Quote

import React, { useRef, useState, useEffect } from 'react'
import Bride from '../../components/DesignOne/Bride'
import Hero from '../../components/DesignOne/Hero'
// import Navbar from '../../components/DesignOne/Navbar'
import WeddingInfo from '../../components/DesignOne/WeddingInfo'
import Story from '../../components/DesignOne/Story'
import Rsvp from '../../components/DesignOne/Rsvp'
import Gifts from '../../components/DesignOne/Gifts'
import Greeting from '../../components/DesignOne/Greeting'
import Footer from '../../components/DesignOne/Footer'
import Quote from '../../components/DesignOne/Quote'
import Music from '../../components/DesignOne/Audio'
import song from '../../assets/sounds/new.mp3'

const JessyWahyu = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [musicAudio, setMusicAudio] = useState('')

  const queryParams = new URLSearchParams(window.location.search)
  const to = queryParams.get('to')
  const resultRef = useRef(null)

  const rootElement = document.querySelector(':root')
  const disableScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft

    window.onscroll = function () {
      window.scrollTo(scrollTop, scrollLeft)
    }

    rootElement.style.scrollBehavior = 'auto'
  }

  useEffect(() => {
    disableScroll()
    setMusicAudio(new Audio(song))
  }, [])

  const enableScroll = () => {
    window.onscroll = function () {}
    resultRef.current?.scrollIntoView({ behavior: 'smooth' })
    setIsPlaying(true)
    musicAudio.play()
  }

  const handlePlay = () => {
    if (isPlaying) {
      musicAudio.pause()
    } else {
      musicAudio.play()
    }
    setIsPlaying(!isPlaying)
  }

  return (
    <div>
      <Hero guest={to} handleClick={() => enableScroll()} />
      {/* <Navbar /> */}
      <Quote ref={resultRef} />
      <Bride />
      <WeddingInfo />
      <Story />
      <Rsvp guest={to} couple={1} />
      <Gifts />
      <Greeting guest={to} couple={1} />
      <Footer />
      <Music handlePlay={handlePlay} isPlaying={isPlaying} />
    </div>
  )
}

export default JessyWahyu

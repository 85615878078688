import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Bride1 from './pages/jessy-wahyu'
import TemplateOne from './pages/template/TemplateOne'
import LandingPage from './pages/LandingPage'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/jessy-wahyu" element={<Bride1 />} />
        <Route path="/template-one" element={<TemplateOne />} />
      </Routes>
    </Router>
  )
}

export default App

import React, { useState, useEffect } from 'react'

const Countdown = ({ time }) => {
  const COUNTDOWN_TARGET = new Date(time)

  const getTimeLeft = () => {
    const total = COUNTDOWN_TARGET - new Date()
    const days = Math.floor(total / (1000 * 60 * 60 * 24))
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
    const minutes = Math.floor((total / (1000 * 60)) % 60)
    const seconds = Math.floor((total / 1000) % 60)

    return { days, hours, minutes, seconds }
  }

  const [timeLeft, setTimeLeft] = useState(() => getTimeLeft())

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft())
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <div className="lg:flex">
      {Object.entries(timeLeft).map((el) => {
        const label = el[0]
        const value = el[1]
        return (
          <div
            key={label}
            className="w-[70px] md:w-32 lg:w-40 py-5 inline-block text-gray-600 border-solid border-gray-500 border-2 lg:border-4 rounded-xl lg:rounded-3xl mx-1 my-5"
          >
            <div className="lg:pb-4 pb-2 text-center">
              <span className="text-base md:text-3xl lg:text-5xl font-bold">
                {value}
              </span>
            </div>
            <span className="lg:text-base text-sm"> {label} </span>
          </div>
        )
      })}
    </div>
  )
}

export default Countdown

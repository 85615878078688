import React from 'react'
import bride from '../../assets/images/jessy.png'
import groom from '../../assets/images/wahyu.png'
import { HeartOutlined, InstagramOutlined } from '@ant-design/icons'
import { FadeUp } from '../Animation'

const Bride = () => {
  return (
    <section className="lg:py-40 py-28 bg-hero-pattern-2 bg-cover text-gray-500">
      <FadeUp>
        <div className="container mx-auto text-center">
          <h2 className="font-sacramento mx-5 lg:mx-0 text-6xl lg:text-7xl font-extrabold">
            Acara Pernikahan
          </h2>
          <h3 className="text-base lg:text-xl m-6 lg:mb-4 font-bold">
            Assalamu'alaikum warahmatullahi wabarakatuh
          </h3>
          <p className="text-xs m-6 lg:text-lg font-bold">
            Dengan memohon rahmat dan ridho Allah Subhanahu Wa Ta'ala,
            Perkenalkanlah kami berdua bernama:
          </p>
          <div className="lg:flex lg:flex-row  mt-10">
            <div className="basis-11/12 flex flex-row items-center lg:pb-0 md:pb-0 pb-10">
              <div className="columns-2xl text-right">
                <h3 className="lg:text-5xl text-2xl font-sacramento font-bold">
                  Jessy Syarastika S.T
                </h3>
                <div className="flex">
                  <div className="w-1/3"></div>
                  <div className="w-2/3">
                    <p className="text-xs mb-4 mt-3 font-semibold py-1">
                      <InstagramOutlined />{' '}
                      <span>
                        <a
                          target="_blank"
                          href="http://instagram.com/jejesyarastika"
                        >
                          @jejesyarastika
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
                <p className="lg:text-lg text-sm font-bold">
                  Putri dari Bapak Sumardi <br />
                  dan <br />
                  Ibu Rahayu Ningsih
                </p>
              </div>
              <img src={bride} alt="Mempelai Wanita" className="w-2/5" />
            </div>
            <div className="basis-1/12 rounded-full -translate-x-2/4 lg:translate-y-16 xl:translate-y-32 inset-x-1/2 bg-white shadow-3xl lg:visible invisible w-12 h-12 flex absolute">
              <HeartOutlined
                style={{ color: '#ec47a4', margin: 'auto', fontSize: '32px' }}
              />
            </div>
            <div className="basis-11/12 flex flex-row-reverse items-center">
              <div className="columns-2xl text-left">
                <h3 className="lg:text-5xl text-2xl font-sacramento font-bold">
                  Wahyu Nur Wahid S.Ian{' '}
                </h3>
                <div className="flex">
                  <div className="w-5/6">
                    <p className="text-xs mb-4 mt-3 font-semibold py-1">
                      <InstagramOutlined />{' '}
                      <span>
                        <a
                          target="_blank"
                          href="http://instagram.com/wahyu_nur_wahid"
                        >
                          @wahyu_nur_wahid
                        </a>
                      </span>
                    </p>
                  </div>
                  <div className="w-1/6"></div>
                </div>
                <p className="lg:text-lg text-sm font-bold">
                  Putra dari Bapak Ali Muntahar <br />
                  dan <br /> Ibu Chusnur Rofiah
                </p>
              </div>
              <img src={groom} alt="Mempelai Wanita" className="w-2/5" />
            </div>
          </div>
        </div>
      </FadeUp>
    </section>
  )
}

export default Bride

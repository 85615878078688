import React, { useState } from 'react'
import { Button, Select, Form, Input, Spin, Modal, Alert } from 'antd'
import axios from 'axios'
import moment from 'moment'
import { ZoomOut } from '../Animation'

const { Option } = Select

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo)
}

const Rsvp = ({ guest, couple }) => {
  const [form] = Form.useForm()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [modalMessage, setModalMessage] = useState('')

  const onConfirmChange = (value) => {
    switch (value) {
      case 'present':
        form.setFieldsValue({
          note: 'present',
        })
        break
      case 'absent':
        form.setFieldsValue({
          note: 'absent',
        })
        break
      case 'doubt':
        form.setFieldsValue({
          note: 'doubt',
        })
        break
      default:
    }
  }

  const onFinish = (values) => {
    const rsvpData = {
      name: values.name,
      number_of_people: values.number_of_people,
      couple: couple,
      confirmation: values.confirmation,
      created_at: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    }
    setIsModalOpen(true)
    axios
      .post(`${process.env.REACT_APP_API_URL}/rsvp`, rsvpData)
      .then((response) => {
        setIsSuccess(true)
        if (values.confirmation === 'present') {
          setModalMessage('Ditunggu kedatanganmu ya!:)')
        } else if (values.confirmation === 'doubt') {
          setModalMessage('Semoga kamu bisa dateng:)')
        } else {
          setModalMessage('Semoga kita bisa ketemu dilain waktu ya:)')
        }
        setTimeout(() => {
          setIsModalOpen(false)
        }, 3000)
      })
      .catch(function (error) {
        console.log(error)
      })
    setTimeout(() => {
      form.resetFields()
    }, 1000)
  }

  return (
    <section className="lg:py-40 py-28 bg-hero-pattern-2 bg-cover text-gray-500">
      <ZoomOut>
        <div className="container mx-auto text-center">
          <h2 className="font-sacramento mx-5 lg:mx-0 text-6xl lg:text-7xl font-extrabold">
            Konfirmasi Kehadiran
          </h2>
          <p className="text-lg m-4 font-bold">
            Isi form dibawah ini untuk konfirmasi kehadiran
          </p>
        </div>
        <Form
          className="mx-auto px-10"
          labelAlign="left"
          name="rsvp"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
            name: guest,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="Nama"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input your name!',
              },
            ]}
          >
            <Input initialvalues={guest} />
          </Form.Item>

          <Form.Item
            label="Jumlah Kehadiran"
            name="number_of_people"
            rules={[
              {
                required: true,
                message: 'Please input number of people!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="confirmation"
            label="Konfirmasi Kehadiran"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Pilih salah satu"
              onChange={onConfirmChange}
              allowClear
            >
              <Option value="present">Hadir</Option>
              <Option value="absent">Tidak Hadir</Option>
              <Option value="doubt">Ragu-ragu</Option>
            </Select>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button className="bg-gray-500 bg-opacity-70" htmlType="submit">
              Submit
            </Button>
          </Form.Item>

          <Modal
            open={isModalOpen}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
          >
            <div className="text-center">
              {isSuccess ? (
                <h1 className="font-mono">{modalMessage}</h1>
              ) : (
                <Spin tip="Loading" size="large">
                  <div className="content" />
                </Spin>
              )}
            </div>
          </Modal>
        </Form>
      </ZoomOut>
    </section>
  )
}

export default Rsvp

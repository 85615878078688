import React from 'react'
import Countdown from './Countdown'
import { ZoomIn } from '../Animation'

const Hero = ({ guest, handleClick }) => {
  return (
    <div
      id="hero"
      className="w-full h-screen bg-hero-pattern p-3 mx-auto text-center flex justify-center items-center bg-cover text-gray-500 drop-shadow-xl"
    >
      <ZoomIn>
        <main>
          <h1 className="font-sacramento font-extrabold text-4xl md:text-6xl lg:text-8xl">
            Jessy & Wahyu
          </h1>{' '}
          <br />
          <p className="text-sm lg:text-xl">
            Akan melangsungkan resepsi pernikahan dalam:
          </p>{' '}
          <Countdown time={'2023-11-04T08:00:00'} />
          <br />
          <h4 className="drop-shadow-lg text-lg lg:text-2xl">
            Kepada{' '}
            <span className="capitalize font-black underline"> {guest}, </span>
          </h4>{' '}
          <br />
          <button
            onClick={handleClick}
            className=" bg-gray-500 bg-opacity-80 text-white hover:bg-gray-700 rounded-xl p-4 font-bold"
          >
            Lihat Undangan{' '}
          </button>
        </main>
      </ZoomIn>
    </div>
  )
}

export default Hero

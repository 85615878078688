import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { weddingLinks } from '../../utils/constants.js'
import { BarsOutlined } from '@ant-design/icons'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="z-10 shadow-md w-full sticky top-0 left-0 backdrop-blur-3xl bg-transparent">
      <div className="md:px-10 py-4 bg-slate-100 px-7 md:flex justify-between items-center">
        {/* Logo */}
        <div className="flex text-2xl cursor-pointer items-center gap-2">
          <span className="font-bold font-sacramento text-2xl">JW</span>
        </div>

        {/* Menu Icon */}
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="w-7 h-7 absolute right-8 top-6 cursor-pointer md:hidden"
        >
          {isOpen ? (
            <span>x</span>
          ) : (
            <span>
              <BarsOutlined />
            </span>
          )}
        </div>

        {/* Nav Links */}
        <ul
          className={`pl-9 md:flex md:items-center md:pb-0 bg-slate-100 pb-12 absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 transition-all duration-500 ${
            isOpen ? 'top-12' : 'left-[-800px]'
          }`}
        >
          {weddingLinks.map((link) => {
            return (
              <li
                key={link.id}
                className="my-7 uppercase link-underline link-underline-black text-black md:my-0 font-semibold md:ml-8"
              >
                <Link to={link.url}>{link.text}</Link>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default Navbar

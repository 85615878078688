import React, { useState } from 'react'
import {
  GiftOutlined,
  CopyOutlined,
  HomeOutlined,
  FileDoneOutlined,
} from '@ant-design/icons'
import { FadeDown } from '../Animation'
import BCA from '../../assets/images/bca.png'
import Mandiri from '../../assets/images/mandiri.png'

const Gifts = () => {
  const giftAccepted = [
    {
      id: 1,
      bank: 'BCA',
      name: 'Wahyu Nur Wahid',
      accountNo: 3880606564,
      logo: BCA,
    },
    {
      id: 2,
      bank: 'Mandiri',
      name: 'Jessy Syarastika',
      accountNo: 1650002832807,
      logo: Mandiri,
    },
  ]

  const [copySuccess, setCopySuccess] = useState('')

  const copyToClipboard = (account) => {
    navigator.clipboard
      .writeText(account)
      .then(() => {
        setCopySuccess(account)
        setTimeout(function () {
          setCopySuccess('')
        }, 1000)
      })
      .catch((error) => console.error('Failed to copy to clipboard:', error))
  }

  return (
    <section className="lg:py-40 py-28 bg-hero-pattern-2 bg-cover text-gray-500">
      <FadeDown>
        <div className="container mx-auto text-center">
          <h2 className="font-sacramento mx-5 lg:mx-0 text-6xl lg:text-7xl font-extrabold">
            Kirim Hadiah
          </h2>
          <p className="text-base mb-4 uppercase font-bold">
            Ungkapan tanda kasih
          </p>
        </div>

        <div className="flex lg:justify-evenly lg:flex-row flex-col items-center">
          {giftAccepted.map((gift, i) => {
            return (
              <div
                key={i}
                className="px-7 py-3 mb-10 lg:w-1/4 md:w-2/5 w-3/4 border bg-gray-100 bg-opacity-60 shadow-[0_3px_10px_rgb(0,0,0,0.2)] border-gray-300 rounded-xl"
              >
                <div className="flex justify-end mb-10">
                  <img className="w-20" src={gift.logo} alt="" />
                </div>
                <div className="flex justify-around">
                  <div className="uppercase font-bold lg:text-base text-xs">
                    <p className="border-b-2 border-gray-300">
                      {gift.accountNo}
                    </p>
                    <p>{gift.name}</p>
                  </div>
                  <button
                    onClick={() => copyToClipboard(gift.accountNo)}
                    className="text-2xl p-2 bg-slate-400 bg-opacity-70 cursor-pointer ml-5 rounded-md hover:bg-slate-500"
                  >
                    {copySuccess === gift.accountNo ? (
                      <FileDoneOutlined />
                    ) : (
                      <CopyOutlined />
                    )}
                  </button>
                </div>
              </div>
            )
          })}
        </div>

        <div className="px-7 text-center py-5 mb-10 mx-auto lg:w-1/2 w-3/4 border bg-gray-100 bg-opacity-60 shadow-[0_3px_10px_rgb(0,0,0,0.2)] border-gray-300 rounded-xl">
          <HomeOutlined className="lg:text-3xl text-2xl" />
          <h1 className="lg:text-2xl text-lg font-bold mb-5 underline">
            Alamat Kirim Hadiah Fisik
          </h1>
          <p className="lg:text-lg text-sm font-semibold">
            Jl. Kastpii RT 3 RW 3, Ds. Klanting, Kec. Sukodono, Kab. Lumajang,
            Jawa Timur
          </p>
          <p className="lg:text-lg text-base font-semibold">
            Penerima : <span className="font-extrabold">Wahyu Nur Wahid</span>
          </p>
        </div>
      </FadeDown>
    </section>
  )
}

export default Gifts

import React from 'react'
import { socialMedia } from '../../utils/constants'

const Footer = () => {
  return (
    <footer>
      <div className="my-3">
        <div className="col text-center">
          <small className="block">
            &copy; 2023 Infinite Invites. All Rights Reserved
          </small>
          <small className="block">
            Made with love by
            <a
              target="_blank"
              className="font-black"
              href="http://instagram.com/infiniteinvites"
            >
              {' '}
              @infiniteinvites
            </a>
            .
          </small>

          <div className="flex justify-center">
            {socialMedia.map((social, index) => {
              return (
                <p key={index} className="px-5 pt-3 text-lg">
                  <a href={social.link}>{social.icon}</a>
                </p>
              )
            })}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

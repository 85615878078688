import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Spin } from 'antd'
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons'
import axios from 'axios'
import moment from 'moment'
import { FadeRight } from '../Animation'

const { TextArea } = Input

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo)
}

const Greeting = ({ guest, couple }) => {
  const [form] = Form.useForm()

  const [isShowing, setIsShowing] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [products, setProducts] = useState([])
  const pageNumbers = []

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i)
  }

  const loadGreeting = async (page) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/greetings/${couple}/?page=${page}&pageSize=5`
      )
      const { data, totalPages } = response.data
      setProducts(data)
      setTotalPages(totalPages)
    } catch (error) {
      console.error('greeting error', error)
    }
  }

  const onFinish = (values) => {
    const userData = {
      name: values.name,
      greeting: values.greeting,
      couple: couple,
      created_at: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    }
    setIsShowing(true)
    axios
      .post(`${process.env.REACT_APP_API_URL}/greetings`, userData)
      .then((response) => {
        setIsShowing(false)
        loadGreeting(currentPage)
      })
      .catch(function (error) {
        console.log(error)
      })
    setTimeout(() => {
      form.resetFields()
    }, 1000)
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    loadGreeting(currentPage)
  }, [currentPage])

  return (
    <section className="lg:py-40 py-28 bg-hero-pattern-2 bg-cover text-gray-500">
      <FadeRight>
        <div className="container mx-auto text-center">
          <h2 className="font-sacramento m-5 lg:mx-0 text-6xl lg:text-7xl font-extrabold">
            Kirim Ucapan
          </h2>
          <p className="text-lg mx-4 uppercase font-bold">
            Kirimkan doa & ucapan kepada kedua mempelai
          </p>
        </div>
        <Form
          className="mx-auto px-10"
          labelAlign="left"
          name="greeting"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
            name: guest,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="Nama"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input your name!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Ucapan"
            name="greeting"
            rules={[
              {
                required: true,
                message: 'Please input your wishes!',
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button className="bg-gray-500 bg-opacity-70" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>

        <div className={`text-center pb-6 ${isShowing ? '' : 'hidden'}`}>
          <Spin /> <span> Loading ...</span>
        </div>

        <div className="lg:w-2/5 w-4/5 mx-auto bg-opacity-70 bg-slate-100 rounded-3xl">
          {products.length >= 1 ? (
            products.map((product) => {
              const timeLeft = moment
                .utc(product.created_at)
                .local()
                .startOf('seconds')
                .fromNow()

              return (
                <div
                  key={product.id}
                  className="mx-8 py-5 border-b-2 border-x-white border-solid"
                >
                  <p className="font-semibold text-base capitalize">
                    {product.name}
                  </p>
                  <p className="text-sm">{product.greeting}</p>
                  <small className="text-xs font-semibold">{timeLeft}</small>
                </div>
              )
            })
          ) : (
            <div className="p-8 text-center mb-3 font-bold">
              <h3>Jadi yang pertama kirimin doa yuk!:)</h3>
            </div>
          )}

          {/* Pagination controls */}
          {products.length >= 1 && (
            <div className="text-center flex justify-around py-10">
              <button
                className={`bg-slate-300 p-3 rounded-lg ${
                  currentPage === 1 ? '' : 'hover:bg-slate-400'
                } `}
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                <DoubleLeftOutlined />
              </button>

              {/* <ul className="flex">
                {pageNumbers.map((number) => (
                  <li
                    key={number}
                    onClick={() => paginate(number)}
                    className={`mx-2 bg-slate-300 p-3 cursor-pointer hover:bg-slate-400 rounded-md ${
                      currentPage === number ? 'bg-slate-400' : ''
                    }`}
                  >
                    {number}
                  </li>
                ))}
              </ul> */}

              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className={`bg-slate-300 p-3 rounded-lg ${
                  currentPage === totalPages ? '' : 'hover:bg-slate-400'
                } `}
              >
                <DoubleRightOutlined />
              </button>
            </div>
          )}
        </div>
      </FadeRight>
    </section>
  )
}

export default Greeting

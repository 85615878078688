import axios from 'axios'
import React, { useContext, useEffect, useReducer } from 'react'
import reducer from '../reducers/greeting_reducer'
import {
  GET_GREETINGS_BEGIN,
  GET_GREETINGS_SUCCESS,
  GET_GREETINGS_ERROR,
  POST_GREETING,
} from '../actions.js'

const initialState = {
  greetingLoading: false,
  greetingError: false,
  greetings: [],
  // offset: 0,
  // currentPageElements: [],
  // elementsPerPage: 5,
  // pagesCount: 1,
  // totalElementsCount: 0,
}

const url = process.env.REACT_APP_API_URL

const GreetingContext = React.createContext()

export const GreetingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [pagination, setPagination] = React.useState({
    limit: 5,
    start: 0,
    page: 1,
    perPage: 5,
  })

  const fetchGreeting = async (url) => {
    dispatch({ type: GET_GREETINGS_BEGIN })
    try {
      const response = await axios.get(url)
      const greetings = response.data.data
      dispatch({ type: GET_GREETINGS_SUCCESS, payload: greetings })
    } catch (error) {
      dispatch({ type: GET_GREETINGS_ERROR })
    }
  }

  const postGreeting = async (url) => {}

  useEffect(() => {
    fetchGreeting(url)
  }, [])

  return (
    <GreetingContext.Provider value={{ ...state, pagination, setPagination }}>
      {children}
    </GreetingContext.Provider>
  )
}

export const useGreetingContext = () => {
  return useContext(GreetingContext)
}

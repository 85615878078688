import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export const FadeUp = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="fade-up" {...props}>
      {props.children}
    </div>
  )
}

export const FadeDown = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="fade-down" {...props}>
      {props.children}
    </div>
  )
}
export const FadeRight = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="fade-right" {...props}>
      {props.children}
    </div>
  )
}
export const FadeLeft = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="fade-left" {...props}>
      {props.children}
    </div>
  )
}

export const FadeUpRight = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="fade-up-right" {...props}>
      {props.children}
    </div>
  )
}

export const FadeUpLeft = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="fade-up-left" {...props}>
      {props.children}
    </div>
  )
}

export const FadeDownRight = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="fade-down-right" {...props}>
      {props.children}
    </div>
  )
}

export const FadeDownLeft = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="fade-down-left" {...props}>
      {props.children}
    </div>
  )
}

export const FlipLeft = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="flip-left" {...props}>
      {props.children}
    </div>
  )
}

export const FlipRight = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="flip-right" {...props}>
      {props.children}
    </div>
  )
}

export const FlipUp = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="flip-up" {...props}>
      {props.children}
    </div>
  )
}

export const FlipDown = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="flip-down" {...props}>
      {props.children}
    </div>
  )
}

export const ZoomIn = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="zoom-in" {...props}>
      {props.children}
    </div>
  )
}

export const ZoomInUp = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="zoom-in-up" {...props}>
      {props.children}
    </div>
  )
}

export const ZoomInDown = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="zoom-in-down" {...props}>
      {props.children}
    </div>
  )
}

export const ZoomInLeft = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="zoom-in-left" {...props}>
      {props.children}
    </div>
  )
}

export const ZoomInRight = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="zoom-in-right" {...props}>
      {props.children}
    </div>
  )
}

export const ZoomOut = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="zoom-out" {...props}>
      {props.children}
    </div>
  )
}

export const ZoomOutUp = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="zoom-out-up" {...props}>
      {props.children}
    </div>
  )
}

export const ZoomUpDown = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="zoom-out-down" {...props}>
      {props.children}
    </div>
  )
}

export const ZoomOutRight = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="zoom-out-right" {...props}>
      {props.children}
    </div>
  )
}

export const ZoomOutLeft = (props) => {
  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  return (
    <div data-aos="zoom-out-left" {...props}>
      {props.children}
    </div>
  )
}

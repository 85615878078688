import React from 'react'

const Gallery = () => {
  return (
    <section className="py-32 bg-pink-100 bg-cover text-gray-500">
      <div className="container mx-auto text-center">
        <small>Memori Kisah Kami</small>
        <h2 className="font-sacramento mx-5 lg:mx-0 text-6xl lg:text-7xl font-extrabold">
          Galery Foto
        </h2>
        <p className="text-lg mb-4">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eos,
          reprehenderit!
        </p>

        <div className="flex flex-wrap mx-16">
          <div className="w-full md:w-1/2 lg:w-1/3 p-4">
            <a
              href="https://picsum.photos/id/112/1200/768"
              data-toggle="lightbox"
              data-gallery="mygallery"
            >
              <img
                src="https://picsum.photos/id/112/300/400"
                className="img-fluid w-full rounded"
                alt=""
              />
            </a>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 p-4">
            <a
              href="https://picsum.photos/id/113/1200/768"
              data-toggle="lightbox"
              data-gallery="mygallery"
            >
              <img
                src="https://picsum.photos/id/113/300/400"
                className="img-fluid w-full rounded"
                alt=""
              />
            </a>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 p-4">
            <a
              href="https://picsum.photos/id/114/1200/768"
              data-toggle="lightbox"
              data-gallery="mygallery"
            >
              <img
                src="https://picsum.photos/id/114/300/400"
                className="img-fluid w-full rounded"
                alt=""
              />
            </a>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 p-4">
            <a
              href="https://picsum.photos/id/115/1200/768"
              data-toggle="lightbox"
              data-gallery="mygallery"
            >
              <img
                src="https://picsum.photos/id/115/300/400"
                className="img-fluid w-full rounded"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Gallery

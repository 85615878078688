import React from 'react'
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons'

const Audio = ({ handlePlay, isPlaying }) => {
  return (
    <div className="z-10">
      <PlayCircleOutlined
        onClick={handlePlay}
        className={`animate-spin-slow ${
          isPlaying ? 'hidden' : ''
        } w-10 h-10 text-5xl fixed bottom-8 right-8 cursor-pointer opacity-60 mix-blend-difference origin-center flex justify-center items-center`}
      />
      <PauseCircleOutlined
        onClick={handlePlay}
        className={`animate-spin-slow  ${
          isPlaying ? '' : 'hidden'
        } w-10 h-10 text-5xl fixed bottom-8 right-8 cursor-pointer opacity-60 mix-blend-difference origin-center flex justify-center items-center`}
      />
    </div>
  )
}

export default Audio

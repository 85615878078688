import React from 'react'
import Background from '../../assets/images/jessy.png'

const Story = () => {
  return (
    <div className="container mx-auto px-14 bg-hero-pattern-2 bg-cover">
      <section className="py-40 text-gray-500">
        <div className="container mx-auto text-center">
          {/* <small className="uppercase indent-1">
            Bagaimana cinta kami berseri
          </small> */}
          <h2 className="font-sacramento text-7xl font-extrabold mb-10 mt-6">
            Cerita Kami
          </h2>
          <p className="text-lg mb-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim
            sapiente molestias officia perspiciatis, reiciendis dicta.
          </p>
        </div>

        {/* <div className="container mx-auto text-center">
          <h2 className="font-sacramento mx-5 lg:mx-0 text-6xl lg:text-7xl font-extrabold">
            Acara Pernikahan
          </h2>
          <h3 className="text-lg lg:text-xl m-6 lg:mb-4">
            Assalamu'alaikum warahmatullahi wabarakatuh
          </h3>
          <p className="text-base m-6 lg:text-lg">
            Dengan memohon rahmat dan ridho Allah Subhanahu Wa Ta'ala,
            Perkenalkanlah kami berdua bernama:
          </p>
          <div className="lg:flex lg:flex-row mt-10">
            <div className="basis-11/12 flex flex-row items-center">
              <div className="columns-2xl text-right">
                <h3 className="text-4xl mb-4 font-sacramento font-bold">
                  Jessy Syarastika
                </h3>
                <p className="text-sm">
                  Putri dari Bapak Sumardi <br />
                  dan Ibu <br />
                  Rahayu Ningsih
                </p>
              </div>
              <img src={bride} alt="Mempelai Wanita" className="w-2/5" />
            </div>
            <div className="basis-1/12 rounded-full -translate-x-2/4 lg:translate-y-16 xl:translate-y-32 inset-x-1/2 bg-white shadow-3xl lg:visible invisible w-12 h-12 flex absolute">
              <HeartOutlined
                style={{ color: '#ec47a4', margin: 'auto', fontSize: '32px' }}
              />
            </div>
            <div className="basis-11/12 flex flex-row-reverse items-center">
              <div className="columns-2xl text-left">
                <h3 className="text-4xl mb-4 font-sacramento font-bold">
                  Wahyu Nur Wahid{' '}
                </h3>
                <p className="text-sm">
                  Putra dari Bapak Ali Muntahar <br />
                  dan Ibu <br />
                  Chusnur
                </p>
              </div>
              <img src={groom} alt="Mempelai Wanita" className="w-2/5" />
            </div>
          </div>
        </div> */}

        <div>
          <ul className="py-6 px-0 mt-4 relative before:top-0 before:bottom-0 before:absolute before:w-px before:inset-1/2 lg:before:bg-gray-300">
            <li className="mb-6 lg:ml-0 ml-5 relative before:table after:table after:clear-both">
              <div
                className="lg:w-40 lg:h-40 w-28 h-28 lg:top-2.5 bg-gray-100 absolute lg:inset-1/2 rounded-full bg-cover bg-center bg-no-repeat -translate-x-2/4"
                style={{ backgroundImage: `url(${Background})` }}
              ></div>
              <div className="lg:w-2/5 md:w-3/5 w-3/4 relative float-right lg:translate-x-0 md:-translate-x-16 translate-x-2 lg:float-left bg-white border-solid border-2 border-gray-300 lg:p-8 p-6 rounded-lg before:inline-block before:absolute before:top-[80px] before:-right-[15px] before:border-solid before:border-t-[15px] before:border-t-transparent before:border-l-[15px] before:border-l-gray-300 before:border-r-0 before:border-r-white before:border-b-[15px] before:border-b-transparent after:inline-block after:absolute after:top-[81px] after:-right-[13px] after:border-solid after:border-t-[14px] after:border-t-transparent after:border-l-[14px] after:border-l-white after:border-r-0 after:border-white after:border-b-[14px] after:border-b-transparent">
                <div className="mb-3">
                  <h3 className="text-gray-600 font-bold md:text-base lg:text-xl mb-1">
                    Mulai serius
                  </h3>
                  <span className="uppercase text-sm text-gray-400">
                    1 Agustus 2021
                  </span>
                </div>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Placeat sunt assumenda perspiciatis a tempora doloribus?
                  </p>
                </div>
              </div>
            </li>

            <li className="mb-6 relative before:table after:table after:clear-both">
              <div
                className="w-40 h-40 top-2.5 bg-gray-100 absolute inset-1/2 rounded-full bg-cover bg-center bg-no-repeat -translate-x-2/4"
                style={{ backgroundImage: `url(${Background})` }}
              ></div>
              <div className="w-2/5 relative float-right bg-white border-solid border-2 border-gray-300 p-8 rounded-lg before:inline-block before:absolute before:top-[80px] before:border-solid before:border-t-[15px] before:border-t-transparent before:border-l-gray-300 before:border-r-gray-300 before:border-b-[15px] before:border-b-transparent after:inline-block after:absolute after:top-[81px] after:border-solid after:border-t-[14px] after:border-t-transparent after:border-l-white after:border-white after:border-b-[14px] after:border-b-transparent before:-left-[15px] before:right-auto before:border-l-0 before:border-r-[15px] after:border-l-0 after:border-r-[14px] after:-left-[13px] after:right-auto">
                <div className="mb-3">
                  <h3 className="text-gray-600 font-bold text-xl mb-1">
                    Pertemuan orang tua
                  </h3>
                  <span className="uppercase text-sm text-gray-400">
                    1 Agustus 2022
                  </span>
                </div>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Placeat sunt assumenda perspiciatis a tempora doloribus?
                  </p>
                </div>
              </div>
            </li>

            <li className="mb-6 relative before:table after:table after:clear-both">
              <div
                className="w-40 h-40 top-2.5 bg-gray-100 absolute inset-1/2 rounded-full bg-cover bg-center bg-no-repeat -translate-x-2/4"
                style={{ backgroundImage: `url(${Background})` }}
              ></div>
              <div className="w-2/5 relative float-left bg-white border-solid border-2 border-gray-300 p-8 rounded-lg before:inline-block before:absolute before:top-[80px] before:-right-[15px] before:border-solid before:border-t-[15px] before:border-t-transparent before:border-l-[15px] before:border-l-gray-300 before:border-r-0 before:border-r-white before:border-b-[15px] before:border-b-transparent after:inline-block after:absolute after:top-[81px] after:-right-[13px] after:border-solid after:border-t-[14px] after:border-t-transparent after:border-l-[14px] after:border-l-white after:border-r-0 after:border-white after:border-b-[14px] after:border-b-transparent">
                <div className="mb-3">
                  <h3 className="text-gray-600 font-bold text-xl mb-1">
                    Bertunangan
                  </h3>
                  <span className="uppercase text-sm text-gray-400">
                    1 Agustus 2023
                  </span>
                </div>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Placeat sunt assumenda perspiciatis a tempora doloribus?
                  </p>
                </div>
              </div>
            </li>

            <li className="mb-6 relative before:table after:table after:clear-both">
              <div
                className="w-40 h-40 top-2.5 bg-gray-100 absolute inset-1/2 rounded-full bg-cover bg-center bg-no-repeat -translate-x-2/4"
                style={{ backgroundImage: `url(${Background})` }}
              ></div>
              <div className="w-2/5 relative float-right bg-white border-solid border-2 border-gray-300 p-8 rounded-lg before:inline-block before:absolute before:top-[80px] before:border-solid before:border-t-[15px] before:border-t-transparent before:border-l-gray-300 before:border-r-gray-300 before:border-b-[15px] before:border-b-transparent after:inline-block after:absolute after:top-[81px] after:border-solid after:border-t-[14px] after:border-t-transparent after:border-l-white after:border-white after:border-b-[14px] after:border-b-transparent before:-left-[15px] before:right-auto before:border-l-0 before:border-r-[15px] after:border-l-0 after:border-r-[14px] after:-left-[13px] after:right-auto">
                <div className="mb-3">
                  <h3 className="text-gray-600 font-bold text-xl mb-1">
                    Pertama bertemu
                  </h3>
                  <span className="uppercase text-sm text-gray-400">
                    1 Agustus 2009
                  </span>
                </div>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Placeat sunt assumenda perspiciatis a tempora doloribus?
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  )
}

export default Story
